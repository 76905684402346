.hd-quote {
	@include serif-font();
    display: block;
    margin: 0;
    padding: 0;
    line-height: 1.2414;
    font-weight: 400;
    clear: both;
    position: relative;
    z-index: 1;
    border: none;

    @media only screen and (max-width: 639px) {
	    font-size: 2.8125rem;
	}

    @media only screen and (max-width: 1023px) and (min-width: 640px) {
	    font-size: 2.8125rem;
	}

	@media only screen and (min-width: 1024px) {
		margin-left: auto;
		margin-right: auto;
		max-width: 1140px;
		font-size: 3.625rem;
	}
}

.hd-quote__author {
    padding-left: 40px;
	@include body-font();
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .2em;
    display: block;
    position: relative;
    margin-top: 35px;
    line-height: 1.5;
    font-style: normal;
    text-align: left;

    @media only screen and (max-width: 639px) {
	    font-size: 1rem;
	}
    @media only screen and (max-width: 1023px) and (min-width: 640px) {		
   		font-size: 1.5rem;
	}
	@media only screen and (min-width: 1024px) {
		font-size: 1.5rem;		
   		margin-top: 60px;
	}

	&:before {
		width: 25px;
	    height: 1px;
	    content: "";
	    display: block;
	    position: absolute;
	    top: .65em;
	    left: 0;
	    background-color: currentColor;
	}
}