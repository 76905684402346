// Custom Navigation Bar
.newsletter {
    .navbar-default {
        background-color: $gray-darkest;
        border-color: transparent;
        @include box-shadow;

        .navbar-brand {
            color: $brand-primary;
            @include script-font;

            &.active,
            &:active,
            &:focus,
            &:hover {
                color: darken($brand-primary, 10%);
            }
        }

        .navbar-collapse {
            border-color: fade(white, 2%);
        }

        .navbar-toggle {
            background-color: $brand-primary;
            border-color: $brand-primary;

            .icon-bar {
                background-color: white;
            }

            &:focus,
            &:hover {
                background-color: $brand-primary;
            }
        }

        .nav {
            li {
                a {
                    @include heading-font;
                    font-weight: 400;
                    letter-spacing: 1px;
                    color: white;

                    &:focus,
                    &:hover {
                        color: $brand-primary;
                        outline: none;
                    }
                }
            }
        }
    }

    @media(min-width:768px) {
        .navbar-default {
            background-color: transparent;
            padding: 25px 0;
            transition: all 0.3s ease-in-out;
            border: none;
            box-shadow: none;

            .navbar-brand {
                font-size: 2em;
                transition: all 0.3s ease-in-out;
            }

            .navbar-nav>.active>a {
                border-radius: 3px;
                background: none;
                color: $brand-primary;
            }
        }

        .navbar-default.navbar-shrink {
            background-color: $gray-darkest;
            padding: 10px 0;

            .navbar-brand {
                font-size: 1.5em;
            }
        }
    } // Custom Navigation Bar
    .navbar-custom {
        background-color: white;
        border-color: transparent;

        .navbar-brand {
            color: $theme-primary;
            &:hover,
            &:focus,
            &:active,
            &.active {
                color: darken($theme-primary, 10%);
            }
            span {
                display: inline-block;
            }

            .brand-figure {
                margin-right: 7px;
                img {
                    width: 16px;
                    height: auto;
                    vertical-align: top;
                    transition: width 0.3s;
                }
            }
            .brand-text {
                img {
                    width: 100px;
                    height: auto;
                    vertical-align: top;
                    transition: width 0.3s;
                }
            }
        }
        .navbar-collapse {
            border-color: fade(white, 2%);
        }
        .navbar-toggle {
            background-color: white;
            border-color: $theme-primary;
            @include heading-font;
            color: $theme-primary;
            font-size: 12px;
            &:hover,
            &:focus {
                background-color: $theme-primary;
                color: white;
            }
        }
        .nav {
            li {
                a {
                    @include heading-font;
                    font-weight: 600;
                    font-size: .875em;
                    letter-spacing: 1px;
                    color: $theme-primary;
                    &:hover,
                    &:focus {
                        color: white;
                        outline: none;
                    }
                }
            }
        }
    }

    @media(min-width:768px) {
        .navbar-custom {
            background-color: transparent;
            padding: 25px 0;
            transition: padding 0.3s;
            border: none;
            .navbar-brand {
                font-size: 2em;
                transition: all 0.3s;
            }
            .navbar-nav>.active>a {
                border-radius: 3px;
            }
            .nav {
                li {
                    a {
                        &:hover,
                        &:focus {
                            color: $theme-primary;
                        }
                    }
                }
            }
        }
    } // Navbar Change on Scroll
    @media(min-width:768px) {
        .navbar-custom.affix {
            @include box-shadow;
            background-color: white;
            padding: 10px 0;
            .navbar-brand {
                font-size: 1.5em;
                padding: 0 15px;
                .brand-figure {
                    img {
                        width: 50px;
                        height: auto;
                        vertical-align: top;
                        padding: 5px 5px 5px 0;
                    }
                    margin-right: 7px;
                }
                .brand-text {
                    img {
                        width: 0;
                        height: auto;
                        vertical-align: top;
                    }
                }
            }
        }
    }

    header {
        @include clearfix;
        background-image: url('../img/header-hero-img.jpg');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;
        @include background-cover;
        text-align: center;
        color: black;

        .intro-text {
            padding-top: 100px;
            padding-bottom: 50px;

            @media only screen and (min-width: 768px) {
                padding-top: 250px;
                padding-bottom: 200px;
            }

            .intro-lead-in {
                font-style: normal;
                font-size: 1.5em;
                line-height: 1.3;
                margin-bottom: 25px;
                font-weight: 400;
                text-transform: none;

                @media only screen and (min-width: 768px) {
                    font-style: normal;
                    font-size: 2.5em;
                    line-height: 1.3;
                    margin-bottom: 25px;
                }

                &.small-intro-lead-in {
                    font-size: 1.875em;

                    @media only screen and (min-width: 960px) {
                        font-size: 2em;
                    }
                }
            }

            .intro-heading {
                @include heading-font;
                font-weight: 700;
                font-size: 40px;
                line-height: 1.125;
                margin-bottom: 25px;

                @media only screen and (min-width: 768px) {
                    @include heading-font;
                    font-weight: 700;
                    font-size: 75px;
                    line-height: 75px;
                    margin-bottom: 50px;
                }

                &.small-heading {
                    font-size: 1.5em;
                    line-height: 1.2;

                    @media only screen and (min-width: 960px) {
                        font-size: 3em;
                    }
                }
            }
        }
    }




    header.newsletter-anmeldung {
        background-image: url('../img/header-gray.jpg');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;
        @include background-cover;
        text-align: center;
        color: black;
        min-height: 500px;
        display: flex;
        align-items: center;

        &.footer-einzeilig {
            @media only screen and (min-width: 960px) {
                min-height: calc(100vh - 77px);
            }
        }

        &.footer-mehrzeilig {
            @media only screen and (min-width: 960px) {
                min-height: calc(100vh - 190px);
            }
        }

        .intro-text {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
}


// Footer
.newsletter {
    footer {
        border-top: 3px solid $gold;
        padding: 25px 0 10px;
        text-align: center;
        @media (min-width: 768px) {
            text-align: left;
        }

        span {
            display: inline-block;
        }
        .brand-figure {
            img {
                width: 13px;
                height: auto;
                vertical-align: top;
            }
            margin-right: 7px;
        }
        .brand-text {
            img {
                width: 90px;
                height: auto;
                vertical-align: top;
            }
        }

        span.copyright {
            line-height: 40px;
            @include heading-font;
            text-transform: none;
        }

        ul.quicklinks {
            text-align: center;
            margin-bottom: 0;
            margin-top: 20px;
            line-height: 1;
            @include heading-font;
            text-transform: none;
            vertical-align: bottom;
            font-size: 1.125em;

            @media (min-width: 768px) {
                text-align: left;
                margin-top: 0;
            }
            li {
                a {
                    line-height: 2.5;
                    padding: 0 10px;
                    color: #111;
                }
            }
        }
    }
}