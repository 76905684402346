.header-title {
    position: relative;
    padding: 25px 50px;
    top: 246px;
    background: #f4b12e;
    color: #002856;
    line-height: 1;
    text-align: center;

    @media only screen and (min-width: 768px) {
        padding: 25px 150px;
        position: absolute;
        top: auto;
        bottom: -60px;
        left: 50%;
        transform: translateX(-50%);
    }

    &.header-title--january {
        background: $color-january;
        color: #FFF;
    }

    &.header-title--february {
        background: $color-february;
        color: #FFF;
        bottom: -75px;
    }

    &.header-title--march {
        background: $color-march;
        color: #FFF;
    }

    &.header-title--april {
        background: $color-april;
        color: #FFF;
    }

    &.header-title--may {
        background: $color-may;
        color: #FFF;
    }

    &.header-title--june {
        background: $color-june;
        color: #FFF;
    }

    &.header-title--july {
        background: $color-july;
        color: #FFF;
    }

    &.header-title--august {
        background: $color-august;
        color: #FFF;
    }

    &.header-title--september {
        background: $color-september;
        color: #FFF;
    }

    &.header-title--october {
        background: $color-october;
        color: #FFF;
    }

    &.header-title--november {
        background: $color-november;
        color: #FFF;
    }

    &.header-title--december {
        background: $color-december;
        color: #FFF;
    }
}

.index-kalender-month {
    &.index-kalender {
        section {
            padding: 50px 0;

            @media only screen and (min-width: 768px) {
                padding: 70px 0;
            }
        }
    }

}

.hero-img {
    background-attachment: scroll;
    background-size: cover;
    height: 300px;

    @media screen and (min-width: 768px) {
        height: 500px;
    }

    &.kalender-steps-january {
        background: url('../steps/img/month/steps-kalender-januar-header.jpg') center center no-repeat;
    }
    &.kalender-steps-february {
        background: url('../steps/img/month/steps-kalender-februar-header.jpg') center center no-repeat;
    }
    &.kalender-steps-march {
        background: url('../steps/img/month/steps-kalender-maerz-header.jpg') center center no-repeat;
    }
    &.kalender-steps-april {
        background: url('../steps/img/month/steps-kalender-april-header.jpg') center center no-repeat;
    }
    &.kalender-steps-may {
        background: url('../steps/img/month/steps-kalender-mai-header.jpg') center center no-repeat;
    }
    &.kalender-steps-june {
        background: url('../steps/img/month/steps-kalender-juni-header.jpg') center center no-repeat;
    }
    &.kalender-steps-july {
        background: url('../steps/img/month/steps-kalender-juli-header.jpg') center center no-repeat;
    }
    &.kalender-steps-august {
        background: url('../steps/img/month/steps-kalender-august-header.jpg') center center no-repeat;
    }
    &.kalender-steps-september {
        background: url('../steps/img/month/steps-kalender-september-header.jpg') center center no-repeat;
    }
    &.kalender-steps-october {
        background: url('../steps/img/month/steps-kalender-oktober-header.jpg') center center no-repeat;
    }
    &.kalender-steps-november {
        background: url('../steps/img/month/steps-kalender-november-header.jpg') center center no-repeat;
    }
    &.kalender-steps-december {
        background: url('../steps/img/month/steps-kalender-dezember-header.jpg') center center no-repeat;
    }

}

.kalender-steps-add.kalender-steps-neu section {
    padding: 80px 0;
}

.kalender-steps-add.kalender-steps-neu #section-kalender-details {
    padding-top: 150px;
}


#section-year {
    .container {
        .row {

            .a-month {
                visibility: hidden;
                position: relative;
                display: block;
                margin: 2em 0;
                background-color: transparent;
                color: #222;
                text-align: center;

                &:after {
                    display: block;
                    padding-bottom: 100%;
                    width: 100%;
                    height: 0;
                    border-radius: 50%;
                    border: 1px solid #ccc;
                    content: "";
                    transition: background .5s ease-in-out;
                }



                &.a-month--active {
                    &:after {
                        border: 1px solid $brand-primary;
                    }

                    a {
                        display: block;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 72px;

                        &:before {
                            display: none;
                        }
                    }

                    a:hover,
                    a:active,
                    a:focus {
                        color: #FFF;
                    }

                    &:hover {

                        a:link,
                        a:visited {
                            color: #FFF;
                        }

                        &:after {
                            background: $brand-primary;
                            color: #FFF;
                        }
                    }

                    &.a-month--january {
                        &:after {
                            border: 1px solid $color-january;
                        }

                        a:link,
                        a:visited {
                            color: $color-january;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-january;
                            }
                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--february {
                        &:after {
                            border: 1px solid $color-february;
                        }

                        a:link,
                        a:visited {
                            color: $color-february;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-february;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--march {
                        &:after {
                            border: 1px solid $color-march;
                        }

                        a:link,
                        a:visited {
                            color: $color-march;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-march;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--april {
                        &:after {
                            border: 1px solid $color-april;
                        }

                        a:link,
                        a:visited {
                            color: $color-april;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-april;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--may {
                        &:after {
                            border: 1px solid $color-may;
                        }

                        a:link,
                        a:visited {
                            color: $color-may;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-may;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--june {
                        &:after {
                            border: 1px solid $color-june;
                        }

                        a:link,
                        a:visited {
                            color: $color-june;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-june;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--july {
                        &:after {
                            border: 1px solid $color-july;
                        }

                        a:link,
                        a:visited {
                            color: $color-july;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-july;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--august {
                        &:after {
                            border: 1px solid $color-august;
                        }

                        a:link,
                        a:visited {
                            color: $color-august;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-august;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--september {
                        &:after {
                            border: 1px solid $color-september;
                        }

                        a:link,
                        a:visited {
                            color: $color-september;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-september;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--october {
                        &:after {
                            border: 1px solid $color-october;
                        }

                        a:link,
                        a:visited {
                            color: $color-october;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-october;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--november {
                        &:after {
                            border: 1px solid $color-november;
                        }

                        a:link,
                        a:visited {
                            color: $color-november;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-november;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }

                    &.a-month--december {
                        &:after {
                            border: 1px solid $color-december;
                        }

                        a:link,
                        a:visited {
                            color: $color-december;
                        }

                        a:hover,
                        a:active,
                        a:focus {
                            color: #FFF;
                        }

                        &:hover {
                            &:after {
                                background: $color-december;
                            }

                            a:link,
                            a:visited {
                                color: #FFF;
                            }
                        }
                    }
                }

                .circle__inner {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .circle__wrapper {
                    display: table;
                    width: 100%;
                    height: 100%;
                }

                .circle__content {
                    display: table-cell;
                    padding: 1em;
                    vertical-align: middle;

                    @media (min-width: 480px) {
                        font-size: 1em;
                    }

                    @media (min-width: 768px) {
                        font-size: 1em;
                    }


                }
            }
        }
    }
}