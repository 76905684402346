.slick-slide {
    margin: 0px 20px;
}

.slick-slide img {
    //width: 100%;
}

.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;
    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    outline: none;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


/*-----------------------------------------------------------------------------------
    PROJEKT-SLIDER
-----------------------------------------------------------------------------------*/
.section-project {
    padding: 10px 0 30px;
    position: relative;
}

// Project carousel outer wrapper
.project-carousel{
    position: relative;
    padding: 50px 0;
}

// Project Strip 
.project-strip{
    margin: 150px 0 150px;
    /* filter: blur(5px); */
    opacity: .5;
    
    .project{
        cursor: pointer;
    }

    img{
        max-width: 100%;
        width: 100%;
        height: auto;
    }
}

// Project Details
.project-screen{
    width: 300px;
    height: 176px;
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translateX(-50%);

    @media only screen and (max-width: 479px) { 
        width: 300px;
        height: 176px;
    }

    @media only screen and (max-width: 599px) and (min-width: 480px) { 
        width: 460px;
        height: 270px;
    }

    @media only screen and (max-width: 767px) and (min-width: 600px) { 
        width: 580px;
        height: 341px;
    }

    @media only screen and (min-width: 768px) { 
        width: 768px;
        height: 451px;
        transform: none;
        left: 0;
    }
    
    // Project Detail Carousel    
    .project-detail{
        @include clearfix();
        position: absolute;
        left: 50%;
        margin: 7px auto 0;
        z-index: 1;
        transform: translateX(-50%);

        @media only screen and (max-width: 479px) { 
            width: 218px;
        }

        @media only screen and (max-width: 599px) and (min-width: 480px) { 
            width: 336px;
            margin: 15px auto 0;
        }

        @media only screen and (max-width: 767px) and (min-width: 600px) { 
            width: 440px;
            margin: 15px auto 0;
        }

        @media only screen and (min-width: 768px) { 
            width: 578px;
            margin: 30px auto 0;
        }

        .slick-slide {
            @media only screen and (max-width: 479px) { 
                margin: 0 5px;
            }

            @media only screen and (max-width: 599px) and (min-width: 480px) { 
               margin: 0 10px;
           }
           @media only screen and (max-width: 767px) and (min-width: 600px) { 
            margin: 0 15px;
        }

        img {
            @media only screen and (max-width: 479px) { 
                max-width: 218px;
                margin-left: -5px;
            }

            @media only screen and (max-width: 599px) and (min-width: 480px) { 
                width: 336px;
                margin-left: -10px;
            }

            @media only screen and (max-width: 767px) and (min-width: 600px) { 
                width: 440px;
                margin-left: -15px;
            }

            @media only screen and (min-width: 768px) { 
                width: 560px;
                margin-left: -10px;
            }

        }

        > div {
            margin-top: 50px;
            text-align: center;
            left: 0;
            position: relative;
            opacity: 0;
            transition: all 3s;


            @media only screen and (max-width: 479px) { 
                max-width: 218px;
                margin-left: -5px;

            }

            @media only screen and (max-width: 599px) and (min-width: 480px) { 
                width: 336px;
                left: -10px;
                margin-top: 80px;
            }

            @media only screen and (max-width: 767px) and (min-width: 600px) { 
                width: 440px;
                left: -15px;
                margin-top: 80px;
            }

            @media only screen and (min-width: 768px) { 
                width: 560px;
                margin-left: -10px;
                margin-top: 100px;
            }
        }

        &.slick-active {
            > div {
                opacity: 1;
            }
        }
    }
}

    .screen-frame{
        content:" ";
        position: absolute;
        background-image: url("../img/laptop-mockup-frame.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        transform: translateX(-50%);
        left: 50%;
        width: 300px;
        height: 176px;

        @media only screen and (max-width: 479px) and (min-width: 480px) { 

        }

        @media only screen and (max-width: 599px) and (min-width: 480px) { 
            width: 460px;
            height: 270px;
        }

        @media only screen and (max-width: 767px) and (min-width: 600px) { 
            width: 580px;
            height: 341px;
        }

        @media only screen and (min-width: 768px) { 
            width: 768px;
            height: 451px;
        }
    }
    
    // Initial style to stack the slides    
    .project{
        margin-right: -100%;
        float: left;
        cursor: move;
        outline: none;
    }
    
}


/*-----------------------------------------------------------------------------------
    TESIMONIAL-SLIDER
-----------------------------------------------------------------------------------*/
.testimonial-wrapper {
    max-width: 910px;
    margin: 0 auto;
    img {
        max-width: 100%;
        height: auto;
    }
    .testimonial-thumb-slider {
       margin: 0;

       .slick-list {
        padding: 20px 0;
    }

    .slick-slider-item {
        padding: 5px;
        box-shadow: 0 0 40px 0 #e3edfc;
        border-radius: 50%;
        height: 100px !important;
        width: 100px !important;
    }
}

.testimonial-thumb-slider 
.testimonial-thumb-slider .slick-current img {
      //outline-color: green;
  }

  .testimonial-item-slider {
    box-shadow: 0 0 40px 0 #e3edfc;

    .slick-slide {
        padding: 100px;
    }
}
.slick-slider {
  margin-bottom: 0px;
}
.controls {
  text-align: center;
  padding: 10px;
  font-family: monospace;
  font-weight: bold;
}

}

.reviews {
    margin-top: 50px;
}

.review-slider-container {
    top: -100px; 
}
.reviews .slick-slider {
    z-index: 9;
}

.reviews .sin-testiImage img {
    transform: scale(0.2);
    transition: all 1s;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    margin: 15px auto;
    box-shadow: 0 0 40px 0 #e3edfc;
    border: 15px solid #fff;
}

.reviews .sin-testiImage.slick-current img {
    transform: scale(.5);
    transition: all 1s;
}

.reviews .reviews-slider {
    position: relative;
    bottom: -70px;
    z-index: 10;
}

.reviews .reviews-text-slider .icon-angle-left,
.reviews .reviews-text-slider .icon-angle-right {
    position: absolute;
    top: 0;
    height: 100%;
    width: 200px;
    cursor: pointer;

    &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        width: 40%;
        height: 1px;
        background-color: #000;
        transition: transform .3s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
}
.reviews .reviews-text-slider .icon-angle-left {
    left: -40px;
    z-index: 999;

    &:before, &:after { 
        transform-origin: 0 50%;
    }
}

.reviews .reviews-text-slider .icon-angle-right {
    right: -160px;

    &:before, &:after { 
        transform-origin: 100% 50%;
    }
}

.reviews .reviews-text-slider {
    margin: auto;
    padding: 70px 30px 30px;
    position: relative;
    text-align: center;
    z-index: 9;
    box-shadow: 0 0 40px 0 #e3edfc;
    top: -70px;

    @media only screen and (min-width: 768px) { 
        padding: 70px 80px 30px;
    }

    &.slick-slider {
        .slick-list {
            padding: 0!important;
        }
    }
}

.reviews .sin-testiText {
    outline: none;
    text-align: center;
    opacity: 0;
    transition: all 3s;

    &.slick-active,
    &.slick-center {
        opacity: 1;
        transition: all 3s;
    }


}

.reviews .sin-testiText p {
    margin-top: 22px;
    line-height: 28px;
    font-size: 18px;
}

.quotes {
    position: absolute;
    width: 100%;
}

.quotes::after,
.quotes::before {
    content: ',,';
    position: absolute;
    font-size: 56px;
    color: #ff3d74;
    font-weight: 700;
    line-height: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block;
    z-index: 999;
}

.reviews .grid-padding-x > .cell:nth-child(2) .quotes::before {
    top: 70px;
    left: -10px;
}

.reviews .grid-padding-x > .cell:nth-child(2) .quotes::after {
    top: 132px;
    right: 20px;
    transform: rotate(0);
}



.slick-arrow {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    cursor: pointer;
    display: none!important;

    @media only screen and (min-width: 768px) { 
        display: block!important;
    }
}

.slick-next {
    right: -370px;
}
.slick-prev {
    left: -370px;
}

.the-arrow {
    position: absolute;
    top: 49%;
    display: block;
    width: 100px;
    height: 1px;
    background-color: #abadb0;
    transition: all .3s ease-in-out;
}

.the-arrow:after, .the-arrow:before {
    content: "";
    display: block;
    position: absolute;
    width: 30%;
    height: 1px;
    background-color: #abadb0;
    transition: all .3s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.slick-next .the-arrow {
    right: 320px;
}
.slick-prev .the-arrow {
    left: 320px;
}
.slick-next .the-arrow:after, .slick-next .the-arrow:before {
    right: 0;
    transform-origin: 100% 50%;
}

.slick-next:hover .the-arrow {
    right: 310px;
}

.slick-next:hover .the-arrow:before {
    transform: rotate(30deg);
}

.slick-next:hover .the-arrow:after {
    transform: rotate(-30deg);
}

.slick-prev .the-arrow:after, .slick-prev .the-arrow:before {
    left: 0;
    transform-origin: 0 50%;
}

.slick-prev:hover .the-arrow {
    left: 310px;
}

.slick-prev:hover .the-arrow:before {
    transform: rotate(-30deg);
}
.slick-prev:hover .the-arrow:after {
    transform: rotate(30deg);
}


.slick-dots {
  width: 100%;
  padding: 5% 0 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  display: inline-block;
  position: relative;
  pointer-events: none;
}
.slick-dots li:not(.slick-active) {
  display: none;
}
.slick-dots li .slick-dots-current {
  -webkit-animation: slick-dot-opening .5s;
  animation: slick-dot-opening .5s;
}
@-webkit-keyframes slick-dot-opening {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
}
@keyframes slick-dot-opening {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
}
.slick-dots-button {
    padding: 0;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    outline: none;
    font-size: 11px;
    letter-spacing: .2em;
    display: flex; 
    align-items: center;
}
.slick-dots-current, .slick-dots-total {
    display: inline-block;
    width: 25px;
    display: inline-block;
    font-size: 18px;
}
.slick-dots-current {
    text-align: right;
}
.slick-dots-total {
    text-align: left;
    color: #b0b0b0;
}
.slick-dots-separator {
    position: relative;
    margin: 0 1em;
    width: 65px;
    display: inline-block;
}
.slick-dots-separator::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    width: 100%;
    display: inline-block;
    background: #b0b0b0;
}

.reviews .slick-dots,
.project-detail .slick-dots {
    position: absolute;
    left: 0;
    bottom: -55px;

    @media only screen and (min-width: 768px) { 
        bottom: -85px;
    }
}

.project-carousel {
    button.slick-next.slick-arrow,
    button.slick-prev.slick-arrow {
        background: none;
        border: none;
        text-indent: -9999px;
        outline: none;
    }
}