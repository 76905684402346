/*-----------------------------------------------------------------------------------
    HEADER / HERO
-----------------------------------------------------------------------------------*/

header.hero-img {
    @include clearfix;
    background: url("../img/header-hero-img.jpg") center center no-repeat;
    background-attachment: scroll;
    background-size: cover;
    //text-align: center;
    color: black;
    position: relative;
    z-index: 10;
    padding-top: 54px;

    @media only screen and (min-width: 768px) {
        padding-top: 0;
    }

    .intro-text {
        .intro-lead-in {
            font-style: normal;
            font-size: 1.5em;
            line-height: 1.3;
            margin-bottom: 25px;
            font-weight: 400;
            text-transform: none;

            @media only screen and (min-width: 768px) {
                font-style: normal;
                font-size: 2em;
                line-height: 1.3;
                margin-bottom: 25px;
            }

            &.small-intro-lead-in {
                font-size: 1.875em;

                @media only screen and (min-width: 960px) {
                    font-size: 2em;
                }
            }

            h2 {
            }
        }

        .intro-heading {
            @include heading-font;
            font-weight: 700;
            font-size: 2.5em;
            line-height: 1.125;
            margin-bottom: 25px;
            position: relative;
            display: inline;
            z-index: 1;
            padding: 0 10px;

            @media only screen and (min-width: 768px) {
                font-size: 3.5em;
                margin-bottom: 50px;
            }

            @media only screen and (min-width: 992px) {
                font-size: 4.5em;
                margin-bottom: 50px;
            }

            &.small-heading {
                font-size: 3em;
                line-height: 1.2;

                @media only screen and (min-width: 960px) {
                    font-size: 4em;
                }
            }

            &.txt-white {
                color: #fff;
            }
        }

        p {
            font-size: 1.875em;
            font-weight: 400;
        }
    }
}

/*-----------------------------------------------------------------------------------
    Additional STYLE-CLASSES
-----------------------------------------------------------------------------------*/

.verticalAlignedMiddle {
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 350px;

        @media only screen and (min-width: 768px) {
            height: 100vh;
        }
    }
}

.pattern-overlay {
    background: url("../img/pattern/pattern_50.png") repeat;
    width: 100%;
    height: 100%;
}

.background-overlay {
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 1) 80%,
        rgba(237, 240, 242, 1) 100%
    );
    width: 100%;
    height: 100%;
    min-height: 680px;
}

/*-----------------------------------------------------------------------------------
    KALENDER-HEADER
-----------------------------------------------------------------------------------*/

header.hero-img.kalender {
    background-image: none;
    text-align: left;
    color: black;
    min-height: auto;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 100px;

    @media only screen and (min-width: 768px) {
        padding-top: 200px;
        min-height: auto;
    }

    .background-overlay {
        height: auto;
    }

    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    // @media only screen and (min-width: 768px) {
    //     .container {
    //         width: 920px;
    //     }
    // }

    @media only screen and (min-width: 1170px) {
        .container {
            width: 1140px;
        }
    }

    @media only screen and (min-width: 1380px) {
        .container {
            width: 1380px;
        }
    }

    /* @media only screen and (min-width: 1440px) {
        .container {
            width: 1380px;
        }
    } */
    .intro-text {
        text-align: center;

        @media only screen and (min-width: 992px) {
            text-align: left;
        }

        .intro-lead-in {
            font-style: normal;
            font-size: 1.5em;
            line-height: 1.3;
            margin-bottom: 25px;
            font-weight: 300;
            text-transform: none;

            @media only screen and (min-width: 768px) {
                font-style: normal;
                font-size: 2.5em;
                line-height: 1.3;
                margin-bottom: 25px;
            }

            &.small-intro-lead-in {
                font-size: 1.5em;

                @media only screen and (min-width: 960px) {
                    font-size: 1.5em;
                    margin-bottom: 60px;
                    margin-top: 50px;
                }
            }
        }

        .intro-heading {
            @include heading-font;
            font-weight: 700;
            font-size: 2.5em;
            line-height: 1.125;
            margin: 25px 0;
            position: relative;
            display: block;
            z-index: 1;
            padding: 0;

            @media only screen and (min-width: 768px) {
                font-size: 3em;
                margin: 40px 0;
            }

            @media only screen and (min-width: 992px) {
                font-size: 3.5em;
                margin: 40px 0;
            }

            @media only screen and (min-width: 1200px) {
                font-size: 4.5em;
                margin: 40px 0;
            }

            &.small-heading {
                font-size: 2.5em;
                line-height: 1.2;

                @media only screen and (min-width: 960px) {
                    font-size: 3em;
                }
            }

            &.txt-white {
                color: #fff;
            }

            span {
                font-size: 21px;
                display: block;
                margin-bottom: 25px;
                font-weight: 500;

                @media only screen and (min-width: 768px) {
                    font-size: 24px;
                    font-weight: 600;
                    margin: 40px 0;
                }
            }
        }

        p {
            font-size: 1.875em;
            font-weight: 400;
        }

        a {
            box-shadow: 0px 10px 27px 0px rgba(172, 38, 74, 0.14);
            margin: 10px;
            text-transform: uppercase;
        }
    }
}

.hero-img.kalender-steps-neu {
    background: url("../img/trurnit-kalender/header-steps-trurnit-kalender.jpg")
        center center no-repeat;
    background-attachment: scroll;
    background-size: cover;
    height: 800px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;

        .intro-text {
            width: 250px;
            padding: 15px;
            background: #f4b12e;
            color: #002856;

            h1 {
                font-size: 3.75em;
                line-height: 1.1;
            }

            h2 {
                font-size: 1.875em;
                line-height: 1.5;
            }

            &:after {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-top-color: #f4b12e;
                border-width: 126px;
                margin-left: -126px;
            }

            img {
                margin-bottom: -50px;
                position: relative;
                z-index: 2;
                margin-top: 16px;
            }
        }
    }
}

.hero-img.kalender-steps {
    background: url("../img/trurnit-kalender/header-steps-trurnit-kalender.jpg")
        center center no-repeat;
    background-attachment: scroll;
    background-size: cover;
    height: 300px;

    @media screen and (min-width: 768px) {
        height: 500px;
    }
}

.hero-img.kalender-die-termine {
    background: url("../img/trurnit-kalender/header-die-termine-trurnit-kalender.jpg")
        center center no-repeat;
    background-attachment: scroll;
    background-size: cover;
    height: 300px;

    @media screen and (min-width: 768px) {
        height: 500px;
    }
}

.hero-img.kalender-koestliches-fuers-ganze-jahr {
    background: url("../img/trurnit-kalender/header-koestliches-fuers-ganze-jahr-trurnit-kalender.jpg")
        center center no-repeat;
    background-attachment: scroll;
    background-size: cover;
    height: 300px;

    @media screen and (min-width: 768px) {
        height: 500px;
    }
}

.hero-img.kalender-energie-und-leben {
    background: url("../img/trurnit-kalender/header-energie-und-leben-trurnit-kalender.jpg")
        center center no-repeat;
    background-attachment: scroll;
    background-size: cover;
    height: 300px;

    @media screen and (min-width: 768px) {
        height: 500px;
    }
}

.hero-img.kalender-individuell {
    background: url("../img/trurnit-kalender/header-individuell-trurnit-kalender.jpg")
        center center no-repeat;
    background-attachment: scroll;
    background-size: cover;
    height: 300px;

    @media screen and (min-width: 768px) {
        height: 500px;
    }
}

.hero-img.kalender-scheckkarten {
    background: url("../img/trurnit-kalender/header-scheckkarten-trurnit-kalender.jpg")
        center center no-repeat;
    background-attachment: scroll;
    background-size: cover;
    height: 300px;

    @media screen and (min-width: 768px) {
        height: 500px;
    }
}

.hero-img-fullwidth {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    @media only screen and (min-width: 992px) {
        right: 0;
        width: 840px;
    }
}

a.bubble-stoerer {
    position: absolute;
    padding: 55.5px 13.5px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    left: -160px;
    bottom:0px;
    font-size: 16px;
    b {
        font-weight: 700;
        font-size: 26px;
    }
    @media screen and (max-width:991px) {
        left:20px;
        position: absolute;
        padding: 38px 5px;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        bottom: 10px;
        font-size: 12px;
        b {
            font-weight: 700;
            font-size: 18px;
        }
    }
}

/*-----------------------------------------------------------------------------------
    SCROLL-BUTTON
-----------------------------------------------------------------------------------*/

.hd-Hero-note {
    display: none;

    @media only screen and (min-width: 768px) {
        letter-spacing: 0.2em;
        bottom: 0;
        left: 50%;
        padding-left: 70px;
        color: #111;
        font-family: proxima-nova, sans-serif;
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        transform: rotate(-90deg) translateY(50%);
        transform-origin: 0 100%;
        display: block;
    }
}

.hd-Hero-note,
.hd-Hero-note:before {
    display: none;

    @media only screen and (min-width: 768px) {
        display: block;
        position: absolute;
        cursor: pointer;
    }
}

.hd-Hero-note:before {
    display: none;

    @media only screen and (min-width: 768px) {
        content: "";
        top: 50%;
        left: 0;
        width: 50px;
        height: 1px;
        background-color: #111;
    }
}

.button {
    cursor: pointer;
    transition: background-color 0.25s ease-out, box-shadow 0.25s ease-out;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    letter-spacing: 1.89px;
    text-transform: uppercase;
    padding: 25px 29px 26px;
    margin-right: 30px;
    display: inline-block;
    outline: none;
    text-decoration: none;
}

.button.white {
    background: #fff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    font-size: 14px;
    color: #00adee;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 0.15s ease-in-out;
}

.features .jagged-hero-content .button {
    width: 180px;
}

.button:last-child {
    margin-right: 0;
}

.button.translucent {
    background: hsla(0, 0%, 100%, 0.11);
    border: 1px solid #fff;
    border-radius: 2px;
    transition: background 0.2s ease-in-out;
}

.button.translucent {
    background: hsla(0, 0%, 100%, 0.11);
    border: 1px solid #fff;
    border-radius: 2px;
    transition: background 0.2s ease-in-out;
}

.features .sub-hero {
    padding-bottom: 30px;
}

.macbook2 {
    display: inline-block;
    max-width: 910px;
    width: 100%;
    margin-bottom: 100px;
}

.features .macbook2 {
    margin-bottom: 160px;
}

.macbook2 .inner {
    padding-top: 53.1868132%;
    position: relative;
}

.macbook2 img {
    position: absolute;
    left: 0;
    top: 0;
}

.logo-stripe {
    margin: 50px 0;
    text-align: center;

    img {
        max-height: 40px;
        padding: 0 20px;
    }
}

.h57 {
    font-size: 28px;
    color: #373636;
    line-height: 51px;
}

.diag {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: 0;
    transform-origin: 0;
}

.hero-diag {
    transform: skewY(-9deg);
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 1) 80%,
        rgba(237, 240, 242, 1) 100%
    );
    z-index: 0;
    max-height: 720px;

    @media only screen and (min-width: 768px) {
        max-height: 940px;
    }

    @media only screen and (min-width: 1440px) {
        max-height: 1200px;
    }
}

@media (min-width: 62em) {
    .hero-diag {
        transform: skewY(-10deg);
    }
}

.hero-diag.smartweb-diag {
    max-height: 900px;

    @media only screen and (min-width: 768px) and (max-width: 990px) {
        max-height: 1200px;
    }

    @media only screen and (min-width: 992px) {
        max-height: 1000px;
    }

    @media only screen and (min-width: 1200px) {
        max-height: 1070px;
    }

    @media only screen and (min-width: 2300px) {
        max-height: 1140px;
    }
}

.circle {
    width: 1500px;
    height: 1500px;
    border-radius: 50%;
    background: #f9fbfe;
    position: absolute;
    right: -200px;
    top: -520px;
}

#kontakt .circle {
    width: 1500px;
    height: 1500px;
    border-radius: 50%;
    background: #f9fbfe;
    position: absolute;
    left: -50px;
    top: 0;
}

.hero-img-container {
    position: relative;

    a:link,
    a:visited {
        position: unset;
        &::before {
            display: none;
        }

        .hero-img-stoerer {
            position: absolute;
            bottom: 100px;
            left: 40%;
            transform: translateX(-50%) scale(1);
            width: 100%;
            max-width: 150px;
            height: 100%;
            max-height: 150px;
            z-index: 1;
            transition: transform 0.3s ease-in-out;
        }
    }

    a:hover,
    a:active,
    a:focus {
        .hero-img-stoerer {
            transform: translateX(-50%) translateY(10px) scale(1.05);
        }
    }
    
    
}