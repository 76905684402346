/*-----------------------------------------------------------------------------------
    FOOTER
-----------------------------------------------------------------------------------*/

footer {
    border-top: 3px solid $gold;
    padding: 25px 0 10px;
    text-align: center;
    @media (min-width: 768px) {
        text-align: left;
    }

    span {
        display: inline-block;
    }
    .brand-figure {
        img {
            width: 13px;
            height: auto;
            vertical-align: top;
        }
        margin-right: 7px;
    }
    .brand-text {
        img {
            width: 90px;
            height: auto;
            vertical-align: top;
        }
    }

    span.copyright {
        line-height: 40px;
        @include heading-font;
        text-transform: none;
    }

    ul.quicklinks {
        text-align: center;
        margin-bottom: 0;
        margin-top: 20px;
        line-height: 1;
        @include heading-font;
        text-transform: none;
        vertical-align: bottom;
        font-size: 1.125em;

        @media (min-width: 768px) {
            text-align: left;
            margin-top: 0;
        }
        li {
            a {
                line-height: 2.5;
                padding: 10px;
                color: #111;
            }
        }
    }
}


footer.footer-social {
    background: #FFF;
    border-top: none;
    padding: 30px 0 10px;
    position: relative;
    z-index: 5;
    text-align: center;
    @media (min-width: 768px) {
        text-align: left;
    }
    .row {
        >div {
            margin: 0 auto;
            text-align: center;
        }
    }

    a:link,
    a:visited {
        padding: 0;
        color: #333;
        &.trurnit-link {
            display: inline-block;

        }
        h5 {
            font-weight: 600;
            padding: 4px 0;
            margin-bottom: 0;
        }
    }
    span {
        display: block;
    }
    .brand-figure {
        img {
            width: 30px;
            height: auto;
            vertical-align: bottom;
        }
        margin-right: 0;
    }
    .brand-text {
        img {
            width: 90px;
            height: auto;
            vertical-align: top;
        }
    }

    span.copyright {
        line-height: 40px;
        @include heading-font;
        text-transform: none;
    }

    ul.quicklinks {
        text-align: center;
        margin: 15px auto;
        line-height: 1;
        @include heading-font;
        text-transform: none;
        vertical-align: bottom;
        font-size: 16px;
        font-weight: 400;

        @media (min-width: 768px) {
            //text-align: left;
            //margin-top: 0;
        }
        li {
            a {
                line-height: 1;
                padding: 10px;
                color: #9e9e9e;
                padding: 4px 0;
                margin: 4px 8px;
            }
        }
    }
}

.socials {
    margin: 0 0 15px;
    padding: 0;
    list-style-type: none;
    text-align: center
}

.socials__item {
    display: inline-block;
    margin: 0 10px;
    min-width: 32px;
    opacity: .8;
    -webkit-transition: opacity .25s ease;
    transition: opacity .25s ease
}

.socials__item:hover {
    opacity: 1
}

.socials__link {
    display: block;

    &:before {
        display: none;
    }
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px;
}
