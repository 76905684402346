/*-----------------------------------------------------------------------------------
    LOGO Grid Rotator
-----------------------------------------------------------------------------------*/
.ri-grid {
    margin: 30px auto 30px;
    position: relative;
    height: auto;

    ul {
        list-style: none;
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0 50px;
        box-sizing: border-box;
        display: flex;

        zoom: 1; 

        &:before,
        &:after {
            content: '';
            display: table;
        }
        &:after {
            clear: both;
        }

        li {
            -webkit-perspective: 400px;
            -moz-perspective: 400px;
            -o-perspective: 400px;
            -ms-perspective: 400px;
            perspective: 400px;
            margin: 0;
            padding: 0;
            float: left;
            position: relative;
            display: block;
            overflow: hidden;
            height: 100px !important;

            a {
                display: block;
                outline: none;
                position: absolute;
                left: 50%;
                top: 0;
                margin-left: -80px;
                width: 150px!important;
                height: 47px !important;
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                -o-backface-visibility: hidden;
                -ms-backface-visibility: hidden;
                backface-visibility: hidden;
                transform-style: preserve-3d;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                box-sizing: border-box;

                &:before {
                    display: none;
                }
            }    
        }
    }

}

/* Grid wrapper sizes */
.ri-grid-size-1{
    width: 55%;
}
.ri-grid-size-2{
    width: 100%;
}
.ri-grid-size-3{
    width: 100%;
    margin-top: 0px;
}

/* Shadow style */
.ri-shadow:after,
.ri-shadow:before{
    content: "";
    position: absolute;
    z-index: -2;
    bottom: 15px;
    left: 10px;
    width: 50%;
    height: 20%;
    max-width: 300px;
    max-height: 100px;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
    transform: rotate(-3deg);
}
.ri-shadow:after{
    right: 10px;
    left: auto;
    transform: rotate(3deg);
}

.ri-grid-loading:after,
.ri-grid-loading:before{
    display: none;
}

.ri-loading-image{
    display: none;
}

.ri-grid-loading .ri-loading-image{
    position: relative;
    width: 30px;
    height: 30px;
    left: 50%;
    margin: 100px 0 0 -15px;
    display: block;
}

.grayscale__ {
    -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
    filter: gray;
    //filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    opacity: .7;
}