// Variables

$brand-primary: #a50034;
$gold: #cdbb88;
$brand-danger: #e74c3c;
$theme-primary:    #a50034;
$gray-darkest: #222;
$gray: #999;
$gray-lighter: #f7f7f7;
$placeholder-text: #bbbbbb;
$body-color: #333;

$color-january-foreground: #e6c285;
$color-february-foreground: #afcb37;
$color-march-foreground: #009ba4;
$color-april-foreground: #afcb37;
$color-may-foreground: #94282f;
$color-june-foreground: #f5b5d3;//main-color
$color-july-foreground: #83d0f5;
$color-august-foreground: #522408;
$color-september-foreground: #00546c;
$color-october-foreground: #f7a941;
$color-november-foreground: #ffce44;
$color-december-foreground: #006f74;

$color-january-background: #006f74;
$color-february-background: #30520f;
$color-march-background: #002856;
$color-april-background: #522408;
$color-may-background: #ffce44;
$color-june-background: #543f55;
$color-july-background: #00546c;
$color-august-background: #afcb37;
$color-september-background: #83d0f5;
$color-october-background: #792573;
$color-november-background: #94282f;
$color-december-background: #e6c285;

// $color-january: #3c6c74;
// $color-february: #558b2e;
// $color-march: #002655;
$color-january: $color-january-background;
$color-february: $color-february-background;
$color-march: $color-march-background;
$color-april: $color-april-background;
$color-may: $color-may-background;
$color-june: $color-june-foreground;
$color-july: $color-july-background;
$color-august: $color-august-background;
$color-september: $color-september-background;
$color-october: $color-october-background;
$color-november: $color-november-background;
$color-december: $color-december-background;


