@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// Bootstrap Button Variant

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;
  @at-root {
    &.active,
    &:active,
    &:focus,
    &:hover,
    .open .dropdown-toggle#{&} {
      color: $color;
      background-color: darken($background, 10%);
      border-color: darken($border, 12%);
    }

    &.active,
    &:active,
    .open .dropdown-toggle#{&} {
      background-image: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] #{&} {
      &,
      &.active,
      &:active,
      &:focus,
      &:hover {
        background-color: $background;
        border-color: $border;
      }
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

@mixin box-shadow {
  box-shadow: 0px 7px 21px 0px rgba(0, 0, 0, 0.1);
}

@mixin top-buffer($mgt) {
  margin-top: $mgt;
} 

// Background Features

@mixin background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

// Font Selections

@mixin serif-font {
  font-family: "freight-display-pro",serif;
}

@mixin script-font {
  font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
}

@mixin body-font {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin heading-font {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
