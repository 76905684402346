/*-----------------------------------------------------------------------------------
    CARD STYLE
-----------------------------------------------------------------------------------*/

.card {
    @include clearfix;
    background-color: #fff;
    margin-bottom: 1.6rem;
    height: 100%;

    @media only screen and (min-width: 768px) {
        margin-bottom: 2.6rem;
    }

    @media only screen and (min-width: 992px) {
        display: flex;
        flex-direction: column;
    }

    /* &.btn__overlap {
        margin-bottom: -40px;
    
        .card__action {
            margin-bottom: -40px;
        }
    } */
}

.card__padding {
    padding: 1.6rem 3.6rem;
}

.card__padding__small {
    padding: 2.6rem;
}

.index-smartweb {
    .card {
        margin-bottom: 0;

        @media only screen and (min-width: 768px) {
            margin-bottom: 1.6rem;
        }
    }
}

.card__image {
    min-height: 100px;
    background-color: #f5f7f9;

    &.border-tlr-radius {
        margin: 30px auto 0;
        border-radius: 50%;
        height: 100px;
        width: 100px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 50%;

            path {
                fill: #69b0cf;
            }
        }

        img {
            position: relative;
            top: 3px;
            width: 105%;
            left: -2px;

            &.contrina,
            &.Check-Icon {
                top: 5px;
                width: 110%;
                left: -5px;
                max-width: 150%;
            }

            &.elektra {
                width: 60%;
                left: 24px;
                top: 15px;
            }

            &.Check-Iconqna {
                top: 8px;
                width: 97%;
                left: 2px;
            }
        }
    }
}

.card__image img {
    width: 100%;
    max-width: 100%;
    display: block;
}

.card__content {
    position: relative;
    flex: 1 0 auto;
    overflow: hidden;

    .p-small-card {
        font-size: 1em;
    }

    .card__article__descr {
        @media only screen and (min-width: 992px) {
            position: absolute;
            z-index: 20;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.8);
            transition: all 0.5s ease-in-out;
            cursor: pointer;
        }

        .card__article__descr_cont {
            @media only screen and (min-width: 992px) {
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                opacity: 0;
                transform: translateY(-100%);
                transition: all 0.5s ease-in-out;
            }
        }

        p {
            color: #333;
            padding: 0;
            font-weight: 400;
            margin-bottom: 1em;

            @media only screen and (min-width: 992px) {
                padding: 0 20px;
            }

            &:last-child {
                @media only screen and (min-width: 992px) {
                    margin-bottom: 0;
                }
            }
        }

        span {
            color: #999;
        }
    }

    &:hover {
        .card__article__descr {
            opacity: 1;

            .card__article__descr_cont {
                @media only screen and (min-width: 992px) {
                    opacity: 1;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.p-fact {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-fact.p-fact-gray {
    color: #8d9091;
    margin-top: 0.5em;
    font-weight: 300;
}

/* card meta */

.card__meta {
    font-size: 1em;
    color: $brand-primary;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 1.5em;
}

/* card action */

.card__action {
    overflow: hidden;
    padding-right: 3.6rem;
    padding-left: 3.6rem;
    padding-bottom: 2.6rem;

    @media only screen and (min-width: 992px) {
        //min-height: 216px;
    }

    a {
        width: 180px;
    }
}

.card__author {
    .card__author-content {
        display: inline-block;
        vertical-align: middle;
        margin-top: 10px;
    }

    span {
        display: block;
    }

    img {
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        margin-right: 0.6em;
        max-width: 100px;
    }
}

.padding-tb {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
}

.shadowDepth0 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.shadowDepth1 {
    box-shadow: 0 0.6rem 4rem rgba(0, 0, 0, 0.08);
}

.col-md-padding-50 {
    @media only screen and (min-width: 992px) {
        padding-right: 50px;
        padding-left: 50px;
    }
}

#angebot .card__content {
    @media only screen and (min-width: 992px) {
        min-height: 260px;
    }
}

#angebot .card__content .card__article {
    h3 {
        span {
            @media only screen and (min-width: 992px) {
                display: block;
            }
        }
    }

    a {
        display: none;

        @media only screen and (min-width: 992px) {
            display: block;
            position: absolute;
            bottom: 30px;
        }
    }
}

#angebot .card__content .card__article__descr {
    background: #fff;
}

.box-shadow-blue {
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(227, 237, 252, 1);
    transition: box-shadow 0.5s;

    &:hover {
        box-shadow: 0px 0px 40px 0px rgba(163, 194, 245, 1);
    }
}

.flex-item {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card__kalender {
    @media only screen and (min-width: 768px) {
        margin: 8px;
    }
    @media only screen and (min-width: 992px) {
        margin: 15px;
    }

    &.col-xs-10 {
        margin-bottom: 15px;
        &.reduce-width {
            max-width:1170px;
        }
    }

    &.col-md-6 {
        @media only screen and (min-width: 768px) {
            width: calc(50% - 16px);
        }
        @media only screen and (min-width: 992px) {
            width: calc(50% - 30px);
        }
    }

    a {
        &:before {
            display: none;
        }

        img {
            transition: transform 0.5s;
        }

        &:hover {
            img {
                transform: scale(0.9);
            }

            .kalender {
                span {
                    &:before {
                        left: 0;
                        right: auto;
                        width: 100%;
                    }
                }
            }
        }
    }

    .kalender {
        @include clearfix();
        color: #333;
        text-align: center;

        @media only screen and (min-width: 768px) {
            text-align: left;
        }

        div:first-child {
            margin-bottom: 2.5em;

            @media only screen and (min-width: 768px) {
                margin-bottom: 0;
            }
        }

        h3 {
            margin-top: 5px;
            margin-bottom: 10px;
        }

        h4 {
            text-transform: none;
            font-weight: 400;
            font-size: 1.125em;
        }

        p {
            font-size: 1em;
            line-height: 1.5;
            margin: 1.5em 0;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                color: $brand-primary;
                text-transform: uppercase;
                display: inline-block;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    height: 1px;
                    background-color: currentColor;
                    transition: width 0.5s;
                    will-change: width;
                    right: 0;
                    width: 0;
                }
            }
        }

        img {
            width: 100%;
            max-width: 300px;
        }

        &.card__padding__small {
            padding: 2.6rem 0;
        }

        .btn-ghost {
            padding: 10px 30px;
            margin-bottom: 0;
        }

        &.kalender-small {
            img {
                max-width: 18%;
            }
        }

        &.kalender-big {
            >div:first-child {
                padding-top: 2.875em;
                padding-left: 2.5em;
            }
            /* >div:last-child {
                padding-top: 1.875em;
            } */
        }
    }
}
